import PropTypes from 'prop-types';
import { Icon, SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';
import { format } from 'bv-helpers/number';
import { format as formatPrice } from 'bv-helpers/price';
import { hasSportsbook } from 'global-config';
import LegListContainer from '../leg_list/leg_list_container';
import EnhancedReturnsView from './enhanced_returns_view';
import EnhancedOddsView from './enhanced_odds_view';
import Header from './header';
import CardHeader from './card_header';
import PendingPayoutCardHeader from './pending_payout_card_header';

const BetItem = ({
  taxDeductPct,
  returns,
  stake,
  taxDeductVal,
  cashOut,
  cashOutValue,
  bogValue,
  toggleExpand,
  isSettled,
  settledDate,
  result,
  expand,
  eachWay,
  betType,
  isManualBet,
  odds,
  fractionalOdds,
  fractionalEnhancedOdds,
  grossNormalReturns,
  grossEnhancedReturns,
  bonusFunds,
  grossReturns,
  legs,
  id,
  addToBetslip,
  isAddingToBetslip,
  renderLegs,
  description,
  createdDate,
  betReference,
  pendingPayout,
}) => {
  const isTaxed = taxDeductPct > 0;
  const hasReturns = parseInt(returns.replace(/\D/g, ''), 10) > 0;
  const returnsGreaterThanStakeOrThereIsTaxDeduct = parseInt(returns.replace(/\D/g, ''), 10) > parseInt(stake.replace(/\D/g, ''), 10) || taxDeductVal > 0;
  const shouldShowTax = isTaxed && hasReturns && returnsGreaterThanStakeOrThereIsTaxDeduct;
  const shouldShowEnhancedReturns = parseInt(String(grossEnhancedReturns).replace(/\D/g, ''), 10) > 0;
  // some old settled betts might not have fractionalOdds
  // in these cases we need to fall back to the old odds field
  const displayOdds = fractionalOdds || odds;

  return (
    <div className="bvs-card account-history__container">
      {cashOut && !pendingPayout
        && (
        <CardHeader
          value={cashOutValue}
          type="cashout"
          icon="cashout"
        />
        )}
      {bogValue && typeof bogValue === 'string' && !pendingPayout
        && (
        <CardHeader
          value={bogValue}
          type="bog"
          icon="bog"
          isBig
        />
        )}
      {pendingPayout && <PendingPayoutCardHeader />}
      <div className="account-history__clickable" onClick={toggleExpand}>
        {isSettled
          && (
          <Header
            date={settledDate}
            result={result}
          />
          )}

        <div className="flex title-row">
          <div className="account-history-dropdown">
            <Icon id={`arrow-solid-circle-${expand ? 'up' : 'down'}`} />
          </div>

          <div className="account-history__title flex--grow">
            {eachWay && `${t('javascript.account.my_bets.sports.details.ew')} `}
            {betType}
          </div>

          <div className="align-right">
            {!isManualBet && fractionalEnhancedOdds === null && displayOdds && `@${formatPrice(displayOdds)}`}
            { fractionalEnhancedOdds !== null && (
            <EnhancedOddsView
              fractionalEnhancedOdds={fractionalEnhancedOdds}
              displayOdds={displayOdds}
            />
            )}
          </div>

          { shouldShowEnhancedReturns && (
            <EnhancedReturnsView
              isSettled={isSettled}
              grossNormalReturns={grossNormalReturns}
              grossEnhancedReturns={grossEnhancedReturns}
            />
          )}
        </div>
        <div className="flex data-row">
          <div className="flex--grow">{t('javascript.account.history.stake')}</div>
          <div className="align-right">
            {isSettled
              ? <span>{t('javascript.account.history.return')}</span>
              : <span>{t('javascript.account.history.potential_return')}</span>}
          </div>
        </div>
        <div className="flex data-row">
          <div className="flex--grow">
            <strong>{format(stake)}</strong>
            {bonusFunds && <Icon className="account-history__bonus-funds" id="bonus-funds" big alternative />}
          </div>
          <div className="align-right">
            <strong>{format(isTaxed ? grossReturns : returns)}</strong>
          </div>
        </div>
        { shouldShowTax && (
          <div className="flex data-row">
            <div className="align-right">
              <span className="account-history__tax-deduct-val">
                {t('javascript.account.my_bets.sports.bet.sport_betting_tax', { percent: taxDeductPct })}
              </span>
              <strong className="account-history__after-tax-return">
                {format(returns)}
              </strong>
            </div>
          </div>
        )}

        {hasSportsbook && isSettled && expand
        && legs[id] && legs[id].unsettledLegs.length > 0 && (
          <div className="account-history__add-to-betslip-link-wrapper">
            <div onClick={addToBetslip} className="bvs-button is-primary account-history__add-to-betslip-link">
              {t('javascript.account.my_bets.sports.add_to_betslip')}
              {isAddingToBetslip && <SmallSpinner />}
            </div>
          </div>
        )}
      </div>

      {renderLegs
        && (
        <LegListContainer
          expand={expand}
          id={id}
          betReference={betReference}
          description={description}
          fallbackCreatedDate={createdDate}
        />
        )}
    </div>
  );
};

BetItem.propTypes = {
  expand: PropTypes.bool.isRequired,
  renderLegs: PropTypes.bool.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  betType: PropTypes.string.isRequired,
  stake: PropTypes.string.isRequired,
  returns: PropTypes.string.isRequired,
  taxDeductVal: PropTypes.number.isRequired,
  taxDeductPct: PropTypes.number.isRequired,
  grossReturns: PropTypes.isRequired,
  grossNormalReturns: PropTypes.isRequired,
  grossEnhancedReturns: PropTypes.isRequired,
  odds: PropTypes.string,
  fractionalOdds: PropTypes.string,
  fractionalEnhancedOdds: PropTypes.string,
  description: PropTypes.string.isRequired,
  createdDate: PropTypes.number.isRequired,
  settledDate: PropTypes.number.isRequired,
  result: PropTypes.string.isRequired,
  cashOut: PropTypes.bool.isRequired,
  cashOutValue: PropTypes.string.isRequired,
  bogValue: PropTypes.string,
  bonusFunds: PropTypes.bool.isRequired,
  eachWay: PropTypes.bool,
  isManualBet: PropTypes.bool.isRequired,
  legs: PropTypes.instanceOf(Array).isRequired,
  addToBetslip: PropTypes.func.isRequired,
  isAddingToBetslip: PropTypes.bool.isRequired,
  isSettled: PropTypes.bool.isRequired,
  betReference: PropTypes.string.isRequired,
  pendingPayout: PropTypes.bool.isRequired,
};

BetItem.defaultProps = {
  odds: null,
  fractionalOdds: null,
  fractionalEnhancedOdds: null,
  bogValue: null,
  eachWay: false,
};

export default BetItem;
