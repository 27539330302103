import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { ListModal, Link } from 'bv-components';
import PropTypes from 'prop-types';
import { useWithdrawalLink, useDepositLink } from 'bv-hooks';
import { navigationOptions } from 'CashierCommon/helpers';

const styles = (link, pathname) => classnames('list-modal-item', {
  active: link === pathname,
});

const CashierHeaderMenu = ({ onCloseClick, isAsiaCashier }) => {
  const { pathname } = useLocation();

  const depositLink = useDepositLink();
  const withdrawLink = useWithdrawalLink();

  return (
    <ListModal
      styleClasses={isAsiaCashier ? 'asia-cashier-list-modal' : ''}
      onCloseClick={onCloseClick}
    >
      {navigationOptions({ depositLink, withdrawLink }).map(({ link, text }) => (
        <Link key={link} to={link} className={styles(link, pathname)} replace data-react>
          {text}
        </Link>
      ))}
    </ListModal>
  );
};

CashierHeaderMenu.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  isAsiaCashier: PropTypes.bool,
};

CashierHeaderMenu.defaultProps = {
  isAsiaCashier: false,
};

export default CashierHeaderMenu;
