import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';
import { format as priceFormat } from 'bv-helpers/price';
import Scoreboards from './scoreboards';
import ExtraDetails from './extra_details';
import Handicaps from './handicaps';

const { showInfoMessage } = window.ModalHelper;

const showRulesInfo = (message) => (e) => {
  e.stopPropagation(); // Prevent leg from expanding when info is clicked
  showInfoMessage({
    iconClosable: false,
    children: message,
    actions: [{
      label: t('javascript.account.profit_and_loss.close'),
      secondary: true,
      close: true,
    }],
  });
};

const LegItem = ({
  result: resultProp,
  inplay,
  toggleExpand,
  sportId,
  expand,
  handicaps,
  title,
  subtitles,
  fractionalOdds,
  bog,
  details,
  ...props
}) => {
  const isOpen = resultProp === 'none' || resultProp === null;

  let result = resultProp;

  if (isOpen) result = inplay ? 'inplay' : 'open';

  const filteredFractionalOdds = (fractionalOdds || []).filter(Boolean);

  const rulesAppliesArray = [
    { props: 'firstPastPost', key: 'first_past_the_post' },
    { props: 'rule4Deduction', key: 'rule4' },
    { props: 'deadHeat', key: 'dead_heat' },
  ];

  const rulesApplies = rulesAppliesArray.filter((item) => props[item.props]);

  const rulesAppliesMessage = (
    <div>
      {rulesApplies.map((item) => (
        <div>
          <h3 className="bvs-msg-box__title">{t(`javascript.cashout_horse_race.${item.key}_title`)}</h3>
          {item.key === 'first_past_the_post'
            ? <p className="bvs-msg-box__title">{t(`javascript.cashout_horse_race.${item.key}_subtitle`)}</p> : ''}
          {item.key === 'rule4'
            ? (
              <p className="bvs-msg-box__title">
                {t(`javascript.cashout_horse_race.${item.key}_subtitle`, { deduction: props.rule4Deduction })}
              </p>
            ) : ''}
          <p>{t(`javascript.cashout_horse_race.${item.key}_body`)}</p>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className="account-history__container"
      onClick={toggleExpand}
    >
      <div className="account-history__header">
        <div className="account-history__legs-wrapper">
          <Icon className="account-history__sport-icon" id={`sport-${sportId}`} alternative />
          <Icon className="bvs-link" id={`arrow-${expand ? 'up' : 'down'}`} />
        </div>
        {handicaps && Object.keys(handicaps).length ? <Handicaps handicaps={handicaps} /> : ''}
        {result
          && (
          <div className={`result-card__ribbon result-card__ribbon--with-border result-card__ribbon--with-border-${result}`}>
            {handicaps && Object.keys(handicaps).length
              ? (
                <span>
                  {t(`javascript.account.my_bets.sports.bet.${handicaps.result1.toLowerCase()}`)}
                  {' / '}
                  {t(`javascript.account.my_bets.sports.bet.${handicaps.result2.toLowerCase()}`)}
                </span>
              )
              : <span>{t(`javascript.account.my_bets.sports.bet.${result}`)}</span>}
          </div>
          )}
      </div>

      <div className="flex title-row">
        <div className="flex--grow">
          <div className="account-history__subtitle">{title}</div>
          {subtitles.map((subtitle) => <div>{subtitle}</div>)}
        </div>
        {filteredFractionalOdds.length > 0
          && (
          <div className="align-right account-history__table--odds">
            {filteredFractionalOdds.map((fractionalOdd) => <div>{`@${priceFormat(fractionalOdd)}`}</div>)}
          </div>
          )}
        {bog
          && (
          <div className="align-right account-history__table--bog-icon">
            <Icon id="bog" big alternative />
          </div>
          )}
      </div>

      {rulesApplies.length > 0
        && (
        <div className="account-history__rules-wrapper">
          <Icon className="account-history__info-icon bvs-link" info onClick={showRulesInfo(rulesAppliesMessage)} />
          {rulesApplies.map((item, index) => (
            <span>
              <span>{t(`javascript.cashout_horse_race.${item.key}_title`)}</span>
              <span>{index + 1 < rulesApplies.length ? ', ' : ' '}</span>
            </span>
          ))}
          <span>{t('javascript.account.my_bets.sports.applies')}</span>
        </div>
        )}

      {expand
        && <ExtraDetails details={details} />}

      <Scoreboards isOpen={isOpen} {...props} />
    </div>
  );
};

LegItem.propTypes = {
  expand: PropTypes.bool.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  result: PropTypes.node.isRequired,
  sportId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitles: PropTypes.node.isRequired,
  fractionalOdds: PropTypes.node.isRequired,
  details: PropTypes.instanceOf(Object).isRequired,
  handicaps: PropTypes.node.isRequired,
  result1: PropTypes.string,
  result2: PropTypes.string,
  handicap1: PropTypes.string,
  handicap2: PropTypes.string,
  bog: PropTypes.bool.isRequired,
  inplay: PropTypes.bool.isRequired,
  firstPastPost: PropTypes.bool.isRequired,
  rule4Deduction: PropTypes.number.isRequired,
  deadHeat: PropTypes.bool.isRequired,
  showScoreboards: PropTypes.bool.isRequired,
  scrA: PropTypes.number.isRequired,
  scrB: PropTypes.number.isRequired,
  dict: PropTypes.instanceOf(Object).isRequired,
  code: PropTypes.number.isRequired,
};

LegItem.defaultProps = {
  result1: null,
  result2: null,
  handicap1: null,
  handicap2: null,
};

export default LegItem;
