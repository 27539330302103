import { t } from 'bv-i18n';

const navigationOptions = ({ depositLink, withdrawLink } = {}) => [
  { link: depositLink || '/bv_cashier/deposit', text: t('deposit') },
  { link: withdrawLink || '/bv_cashier/withdraw', text: t('withdraw') },
  { link: '/bv_cashier/history/5', text: t('javascript.account.overview.banking_history') },
  { link: '/bv_cashier/payments', text: t('javascript.account.overview.manage_payments') },
];

export default navigationOptions;
