import PropTypes from 'prop-types';
import { format as formatPrice } from 'bv-helpers/price';

const EnhancedOddsView = ({ fractionalEnhancedOdds, displayOdds }) => (
  <div className="enhanced-odds__mybet">
    <span className="outcome-price outcome-box enhanced-odds">
      <span className="enhanced-odds__odds">
        <span className="bvs-event-icon is-bet-boost" />
        {formatPrice(fractionalEnhancedOdds)}
      </span>
      <span className="enhanced-odds__previous-odds">{formatPrice(displayOdds)}</span>
    </span>
  </div>
);

EnhancedOddsView.propTypes = {
  fractionalEnhancedOdds: PropTypes.isRequired,
  displayOdds: PropTypes.isRequired,
};

export default EnhancedOddsView;
